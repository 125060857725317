module controllers {
    export module customs {
        interface IAccountUpdateScope extends ng.IScope {
        }

        interface IAccountUpdateScopeParams extends ng.ui.IStateParamsService {
            accId: number;
        }

        export class accountUpdateCtrl {

            static $inject = [
                "$scope",
                "$rootScope",
                "generalService",
                "$q",
                "$anchorScroll",
                "$uibModal",
                "bsLoadingOverlayService",
                "$stateParams",
                "entityService",
                "$state",
                "$timeout",
                "$transitions",
                "documentRepositoryService",
                "accountService",
                "currencyService",
                "classificationValueService",
                "userAccountService",
                "countryService",
                "accountDetailService",
                "customsPartyService",
                "customsOfficeCodeService",
                "uiGridConstants",
                "accountPeriodService",
                "taxTypeService",
                'menuService',
            ];

            dropdownsObject = {
            };

            accId: number;
            account: interfaces.customs.IAccount;

            //Declaration Country Variables
            declarationCountryId: number = 0;
            declarationCountryList: Array<interfaces.applicationcore.IDropdownModel>;
            declarationCountry: interfaces.applicationcore.IDropdownModel;
            //Customs Office Variables
            customsOfficeId: number = 0;
            customsOfficeList: Array<interfaces.applicationcore.IDropdownModel>;
            customsOffice: interfaces.applicationcore.IDropdownModel;

            apiSubAccount: uiGrid.IGridApi; 

            //Currency Variables
            currencyId: number = 0;
            currencyList: Array<interfaces.applicationcore.IDropdownModel>;
            currency: interfaces.applicationcore.IDropdownModel;

            //Customs Party Variables
            customsPartyId: number = 0;
            customsPartyList: Array<interfaces.applicationcore.IDropdownModel>;
            customsParty: interfaces.applicationcore.IDropdownModel;

            //Account Period Variables
            accountPeriodId: number = 0;
            accountPeriodList: Array<interfaces.applicationcore.IDropdownModel>;
            accountPeriod: interfaces.applicationcore.IDropdownModel;

            breadCrumbDesc: string;
            selectedTab: number = 0;
            addendumHTML: string = "";

            isLoadingDetail: boolean = false;

            yesNoItems = [{
                id: null,
                label: 'Please Select',
            }, {
                id: true,
                label: 'Yes',
            }, {
                id: false,
                label: 'No',
            }];

            ownerEntityId: number;
            accountHook: any;

            hasAddAdjustmentRight: boolean = false;
            hasAddReceiptRight: boolean = false;

            constructor(
                private $scope: IAccountUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IAccountUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public accountService: services.customs.accountService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private userAccountService: interfaces.applicationcore.IUserAccountService,
                private countryService: interfaces.applicationcore.ICountryService,
                public accountDetailService: interfaces.customs.IAccountDetailService,
                private customsPartyService: interfaces.master.ICustomsPartyService,
                private customsOfficeCodeService: interfaces.tariff.ICustomsOfficeCodeService,
                private uiGridConstants: uiGrid.IUiGridConstants,
                public accountPeriodService: services.customs.accountPeriodService,
                private taxTypeService: services.tariff.taxTypeService,
                private menuService: interfaces.applicationcore.IMenuService
            ) {

                this.accId = $stateParams.accId;

                this.accountHook = $transitions.onSuccess({
                    to: 'auth.CustomsAccount.Update',
                    from: 'auth.CustomsAccount.Update.**'
                }, () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'account.update'
                    }, () => {

                        return this.loadAccount();
                    });
                });

                var loadPromises: ng.IPromise<any>[] = [];
                loadPromises.push(this.loadAccount());
                loadPromises.push(this.loadCustomParties());
                loadPromises.push(this.getHasAddAjustmentRight());
                loadPromises.push(this.getHasAddReceiptRight());

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'account.update'
                },
                    () => {                        
                        return $q.all(loadPromises);
                    });
            }


            loadAccount() {
                return this.accountService.getAccount(this.accId).query((data: interfaces.customs.IAccount) => {
                    this.account = data;
                    this.breadCrumbDesc = this.account.Code;
                    this.loadAccountPeriods();
                    this.$timeout(() => {
                        //Seperate AccountLineList From the Account and move it on its own
                        //this.accountDetailPeriodChange();
                        this.gvwDetails.data = this.account.AccountLineList;
                        this.gvwPeriodList.data = this.account.AccountPeriodList;
                        if (this.apiPeriodsList) {
                            this.apiPeriodsList.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                        }

                        this.gvwSubAccount.data = this.account.SubAccountList;
                        if (this.apiSubAccount) {
                            this.apiSubAccount.core.notifyDataChange(this.uiGridConstants.dataChange.ALL)
                        }
                        
                        this.breadCrumbDesc = this.account.Number.toString();

                        this.isLoadingDetail = false;
                    });
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            getHasAddAjustmentRight() {
                this.hasAddAdjustmentRight = false;
                return this.menuService.getGTSConnectMenuItem(709).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasAddAdjustmentRight = true;                 
                    }
                                        
                }).$promise;
            }

            getHasAddReceiptRight() {
                this.hasAddReceiptRight = false;
                return this.menuService.getGTSConnectMenuItem(710).get((result: interfaces.applicationcore.IMenu) => {
                    if (result && result.name) {
                        this.hasAddReceiptRight = true;                 
                    }
                                        
                }).$promise;
            }            

            //Sub Account

            registerSubAccountApi(gridApi: uiGrid.IGridApi) {
                this.apiSubAccount = gridApi;
            }

            insertSubAccount(){
                var newSubAccount = <interfaces.customs.ISubAccount>{
                    Id: 0,
                    IsAllOther : false,
                    CustomsAccountId: this.account.Id,
                    CreditLimit : 0,
                    TaxType : null,
                    IsActive: true
                };
                this.account.SubAccountList.push(newSubAccount);
                this.apiSubAccount.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
            }

            checkBoxSubAccountChanged(entity: interfaces.customs.ISubAccount) {    
                if (entity) {
                if (this.apiSubAccount) {
                    this.apiSubAccount.rowEdit.setRowsDirty([entity]);
                }   }
            }

            loadTaxTypes(){
                return this.taxTypeService
                .getDropdownList(this.account.DeclarationCountry.Id)
                .query(
                  () => {},
                  failureData => {
                    this.generalService.displayMessageHandler(<
                      interfaces.applicationcore.IMessageHandler
                    >failureData.data);
                  }
                ).$promise;
            }

            
            subAccountTaxTypeChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.customs.ISubAccount) {
                if (model) {
                    entity.TaxType = model;                 
                    if (this.apiSubAccount) {
                        this.apiSubAccount.rowEdit.setRowsDirty([entity]);
                    }
                }                  
            }

            subAccountCreditLimitChanged(entity: interfaces.customs.ISubAccount){
                if (this.apiSubAccount) {
                    this.apiSubAccount.rowEdit.setRowsDirty([entity]);
                }
            }

            removeInlineSubAccount(entity: interfaces.customs.ISubAccount) {
                console.log("delete",entity);
                

                if (entity.Id === 0) {
                    _.remove(this.gvwSubAccount.data, (o) => {
                        return o === entity;
                    });
                    return;
                }

            }

            gvwSubAccount: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                showColumnFooter: true,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell"  ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerSubAccountApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",                 
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 57,
                        enableCellEditOnFocus: false,                        
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.accountUpdateCtrl.insertSubAccount()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`,
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.accountUpdateCtrl.removeInlineSubAccount(row.entity)" ng-if="row.entity.Id == 0" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        
                    }
                    , {
                        name: "IsAllOther",
                        displayName: "Default",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox"  ng-model="row.entity.IsAllOther" ng-change="grid.appScope.accountUpdateCtrl.checkBoxSubAccountChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox"   ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    },
                    {
                        name: 'TaxType',
                        displayName: '* Tax Type',
                        field: 'TaxType',
                        enableCellEditOnFocus: true,
                        cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>',
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" ng-change="grid.appScope.accountUpdateCtrl.subAccountTaxTypeChanged(model, row.entity)"   append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.accountUpdateCtrl.loadTaxTypes()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    },                    
                    {
                        name: 'CreditLimit',
                        displayName: '* Credit Limit',
                        field: 'CreditLimit',
                        enableCellEditOnFocus: true,
                        cellFilter: "number: 2",
                        type: "number",
                        aggregationType: this.uiGridConstants.aggregationTypes.sum,
                        footerCellTemplate: `<div class="ui-grid-cell-contents" tyle="text-align:right;" >{{col.getAggregationValue() | number:2 }}</div>`,
                        cellClass: 'text-right',
                        cellTemplate: `                                       
                                       <div class="ui-grid-cell-contents"   >{{row.entity.CreditLimit | number:2 }}</div>
                                       `,
                        editableCellTemplate: `
                                <form name="inputForm" >   
                                        <p class="input-group-sm" >
                                        <input type="number" ng-change="grid.appScope.accountUpdateCtrl.subAccountCreditLimitChanged(row.entity)"    ignore-wheel name="AttributeNumericValue" ng-model="row.entity.CreditLimit" class="form-control" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" min="0" />
                                        </p>

                                  </form>
                               `,
                        enableSorting: false,
                        width: 100
                    },
                    {
                        name: 'Balance',
                        displayName: 'Balance',
                        field: 'Balance',
                        enableCellEditOnFocus: false,
                        enableCellEdit: false,
                        cellFilter: "number: 2",
                        type: "number",
                        aggregationType: this.uiGridConstants.aggregationTypes.sum,
                        footerCellTemplate: `<div class="ui-grid-cell-contents" style="text-align:right;" >{{col.getAggregationValue() | number:2 }}</div>`,
                        cellClass: 'text-right',
                        cellTemplate: `<div class="ui-grid-cell-contents"   >{{row.entity.Balance | number:2 }}</div>`,                       
                        enableSorting: false,
                        width: 100
                    }                    
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox"  ng-model="row.entity.IsActive" ng-change="grid.appScope.accountUpdateCtrl.checkBoxSubAccountChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox"   ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            }; 



            apiACCDetailList: uiGrid.IGridApi;
            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiACCDetailList = gridApi;
                this.$timeout(() => {
                    this.gvwDetails.data = this.account.AccountLineList;
                });
            }
            //Build UIGrid
            gvwDetails: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" 
                                        ng-disabled="row.entity.linkedDetailId 
                                                    || row.entity.goodsDeclarationId
                                                    || (row.entity.TypeClassificationValueId === 443 && !grid.appScope.accountUpdateCtrl.hasAddReceiptRight)
                                                    || (row.entity.TypeClassificationValueId === 445 && !grid.appScope.accountUpdateCtrl.hasAddAdjustmentRight)" 
                                        
                                                    ng-click="grid.appScope.accountUpdateCtrl.accLine(
                                                        row.entity.Id,  
                                                        grid.appScope.accountUpdateCtrl.account.DetailPeriodNumber ,
                                                        grid.appScope.accountUpdateCtrl.account.Id,
                                                        row.entity.TypeClassificationValueId)"
                                                        
                                        class="btn btn-default btn-sm">
                                        <span class="fa fa-pencil"></span>
                                    </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 50,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }, 
                },
                {
                    name: "PostingDate",
                    displayName: "Posting Date",
                    field: "PostingDate",
                    width: 100,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (!searchTerm) return true; // Show all rows if no search term is provided
                            if (!cellValue) return false; // If cell value is empty, return false
                
                            // Convert cellValue to "YYYY/MM/DD" format using moment.js
                            const formattedCellValue = moment(cellValue).format("YYYY/MM/DD");
                
                            // Perform a case-insensitive contains match
                            return formattedCellValue.toLowerCase().includes(searchTerm.toLowerCase());
                        }
                    }
                },
                {
                    name: "Reference1",
                    displayName: "Reference 1",
                    field: "Reference1",
                    width: 130,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                },
                {
                    name: "Reference2",
                    displayName: "Reference 2",
                    field: "Reference2",
                    width: 130,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                }, 
                {
                    name: "Consignment",
                    displayName: "Consignment",
                    field: "Consignment",
                    width: 130,
                    cellTemplate: `<div ng-if="row.entity.consignmentId" class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                                        <a style="cursor:pointer" ng-click="grid.appScope.accountUpdateCtrl.ShowConsignment(row.entity.consignmentId, row.entity.IsInbound)"><u>{{MODEL_COL_FIELD}}</u></a>
                                    </div>
                                    <div ng-if="!row.entity.consignmentId" class="ui-grid-cell-contents"></div>`,       
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                },                 
                {
                    name: "ClientName",
                    displayName: "ClientName",
                    field: "ClientName",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                },  
                {
                    name: "ReceiptNo",
                    displayName: "Receipt No.",
                    field: "ReceiptNo",
                    width: 100,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (!searchTerm) return true; // Show all rows if no search term is provided
                            if (!cellValue) return false; // If cell value is empty, return false
                
                            // Convert cellValue to "YYYY/MM/DD" format using moment.js
                            const formattedCellValue = moment(cellValue).format("YYYY/MM/DD");
                
                            // Perform a case-insensitive contains match
                            return formattedCellValue.toLowerCase().includes(searchTerm.toLowerCase());
                        }
                    }
                },  
                {
                    name: "ReceiptDate",
                    displayName: "Receipt Date",
                    field: "ReceiptDate",
                    width: 100,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (!searchTerm) return true; // Show all rows if no search term is provided
                            const searchDate = moment(searchTerm, "YYYY/MM/DD", true);
                            const cellDate = moment(cellValue, "YYYY/MM/DD", true);
                
                            // Ensure both the search term and cell value are valid dates
                            if (!searchDate.isValid() || !cellDate.isValid()) return false;
                
                            // Compare the dates
                            return cellDate.format("YYYY/MM/DD").indexOf(searchDate.format("YYYY/MM/DD")) !== -1;
                        }
                    }
                },                                            
                {
                    name: "Type",
                    displayName: "Type",
                    field: "Type",
                    width: 100,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                }, 
                {
                    name: "TaxType",
                    displayName: "Tax Type",
                    field: "TaxType",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                        }
                    }
                }, 
                {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    cellFilter: "number: 2",
                    width: 150,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            if (!searchTerm) return true; // Show all if search term is empty
                            const term = parseFloat(searchTerm);
                            const value = parseFloat(cellValue);
                            return !isNaN(term) && !isNaN(value) && value.toFixed(2).includes(term.toFixed(2));
                        }
                    }
                },
                {
                    name: "INFO",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                            <div class="GridButton"> 
                                <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                    updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                                </gts-stamp-info>
                            </div>`,
                        width: 38,
                    enableSorting: false,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    enableColumnMenu: false,
                    enableFiltering: false,
                }]
            };



            apiPeriodsList: uiGrid.IGridApi;
            selectedPL: uiGrid.IGridRow;

            registerPerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiPeriodsList = gridApi;

                this.apiPeriodsList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiPeriodsList.selection.selectRow(gridSelection.row.entity);                    
                });

                this.$timeout(() => {
                    this.gvwPeriodList.data = this.account.AccountPeriodList;
                });
            }
            gvwPeriodList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: false,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerPerGridApi(gridApi) },
                
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.accountUpdateCtrl.periodLine(row.entity.Id, grid.appScope.accountUpdateCtrl.account.Id)" class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                {
                    name: "PeriodNumber",
                    displayName: "Period Number",
                    field: "PeriodNumber",
                    width: 150,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                }, 
                {
                    name: "DateFrom",
                    displayName: "Date From",
                    field: "DateFrom",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="DateFrom"></gts-date>                                        
                        </div>`,
                }, 
                {
                    name: "DateTo",
                    displayName: "Date To",
                    field: "DateTo",
                    width: 150,
                    type: "date",
                    cellFilter: 'momentDateFilter: "YYYY/MM/DD"',
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="DateTo"></gts-date>                                        
                        </div>`,
                }]
            };

            periodRemoveClick() {
                var selectedRows = <Array<interfaces.customs.IAccountPeriodDisplay>>this.apiPeriodsList.selection.getSelectedRows();

                if (selectedRows && selectedRows.length <= 0) {
                    return;
                }

                if (!selectedRows[0].Id || selectedRows[0].Id === 0) {
                    this.apiPeriodsList.rowEdit.setRowsClean(selectedRows);
                    (<Array<any>>(this.gvwPeriodList.data)).splice((<Array<any>>(this.gvwPeriodList.data)).lastIndexOf(selectedRows[0]), 1);
                    return;
                }

                this.generalService.displayConfirmationBox("Confirm?", "Are you sure you want to remove the Period Line?").then((result: boolean) => {
                    if (result) {
                        this.removePeriodLine(selectedRows[0].Id);
                    }
                });
            }

            removePeriodLine(Id: number) {
                this.accountService.deletePeriod(Id).delete((result) => {
                    this.generalService.displayMessageHandler(result);

                    this.loadAccount();

                }, (failureMessage) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                });
            }
            

            periodLine_click() {
                this.periodLine(0, this.account.Id, this.account.LockdownType)
            }
            periodLine(periodId: number, accountId: number, LockdownType: number) {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Customs Account Posting Period</h3>
                            </div>
                            <div bs-loading-overlay bs-loading-overlay-reference-id="perlines.update">
                                <form name="accLinesUpdate">
                                    <gts-message-display messages="Messages"></gts-message-display>
                                    <div class="modal-body" id="modal-body">
                                        <div class="gts-padding">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Period Number</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                <input ng-disabled="periodLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="periodLineCtrl.accountPeriodLine.PeriodNumber" name="PeriodNumber" aria-label="Period Number" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Date From</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                    <gts-date ng-disabled="periodLineCtrl.LockdownType>=4" format="yyyy/MM/dd" ng-model="periodLineCtrl.accountPeriodLine.DateFrom" name="DateFrom">
                                                                    </gts-date>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="row vertical-align">
                                                        <div class="col-md-4">
                                                            <req></req>
                                                            <label>Date To</label>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <p class="input-group-sm">
                                                                    <gts-date  ng-disabled="periodLineCtrl.LockdownType>=4" format="yyyy/MM/dd" ng-model="periodLineCtrl.accountPeriodLine.DateTo" name="DateTo">
                                                                    </gts-date>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button class="btn btn-primary" ng-disabled="periodLineCtrl.LockdownType>=4" type="button" ng-click="periodLineCtrl.save()">Submit</button>
                                            <button class="btn btn-primary" type="button" ng-click="periodLineCtrl.cancel()">Close</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        `,
                    controller: class periodLineCtrl {
                        messages: interfaces.applicationcore.IMessage[];
                        accountPeriodLine: interfaces.customs.IAccountPeriod;
                        constructor(
                            $scope,
                            private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public accountPeriodService: services.customs.accountPeriodService,
                            private bsLoadingOverlayService,
                            private $q: ng.IQService,
                            private LockdownType : number
                        ) {

                            var loadPromises: ng.IPromise<any>[] = [];
                            loadPromises.push(this.loadAccountPeriodLine(periodId, accountId));

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'perlines.update'
                            },
                                () => {
                                    return $q.all(loadPromises);
                                });
                        }

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }


                        SaveAccount(): ng.IPromise<boolean> {
                            var deferre = this.$q.defer<boolean>();

                            this.accountPeriodService.save().save(this.accountPeriodLine, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(data);

                                deferre.resolve(true);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                deferre.resolve(false);
                            });

                            return deferre.promise;
                        };

                        save() {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'perlines.update'
                            },
                                () => {
                                    return this.SaveAccount().then((data: boolean) => {
                                        if (data) {
                                            this.$uibModalInstance.close(true);
                                        }
                                    });
                                });

                        }
                        loadAccountPeriodLine(periodId: number, accountId: number) {
                            return this.accountPeriodService.getAccountPeriodDetail(periodId, accountId).query((data: interfaces.customs.IAccountPeriod) => {
                                this.accountPeriodLine = data;
                            }
                                , (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                }).$promise;
                        }
                    },
                    controllerAs: "periodLineCtrl",
                    resolve: {
                        LockdownType: this.account.LockdownType
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadAccount();
                    }
                });
            }

            ShowConsignment(consignmentId:number,IsInbound:boolean){
                var url = "";
                if (IsInbound){
                    url = '#!/ConsignmentImports/UpdateImport/'+consignmentId;
                }
                else{                    
                    url = '#!/ConsignmentExports/UpdateExport/'+consignmentId;
                }
        
                window.open(url, '_blank');
            }

            accLine_click(TypeClassificationValueId: number) {
                this.accLine(0, this.account.DetailPeriodNumber, this.account.Id,TypeClassificationValueId)
            }

            accLine(accountLineId: number, accountPeriodId: number, accountId: number, TypeClassificationValueId: number)
            {
                this.$uibModal.open({
                    animation: true,
                    size: "lg",
                    template: `
                            <div class="modal-header">
                              <h3 class="modal-title" id="modal-title"  ng-if="accLineCtrl.TypeClassificationValueId===445">Add / Edit Adjustment</h3>
                              <h3 class="modal-title" id="modal-title"  ng-if="accLineCtrl.TypeClassificationValueId===443">Add / Edit Receipt</h3>
                                </div>
                                <div bs-loading-overlay bs-loading-overlay-reference-id="acclines.update">
                                    <form name="accLinesUpdate">
                                        <gts-message-display messages="Messages"></gts-message-display>
                                        <div class="modal-body" id="modal-body">
                                            <uib-tabset active="accLineCtrl.selectedTab">
                                                <uib-tab index="0" heading="General">
                                                    <div class="gts-padding">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="row vertical-align">
                                                                    <div class="col-md-4">
                                                                        <req></req>
                                                                        <label>Entity</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm">
                                                                            <gts-dropdown  ng-disabled="accLineCtrl.LockdownType>=4" name="Entity" ng-model="accLineCtrl.accountLine.Entity" load-data="accLineCtrl.loadEntities(searchText)" required="true"></gts-dropdown>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="row vertical-align" ng-if="accLineCtrl.TypeClassificationValueId===443">
                                                                    <div class="col-md-4">
                                                                        <req></req>
                                                                        <label>Receipt No.</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm has-feedback"  ng-class="{'has-error': !accLineCtrl.accountLine.Reference1}">
                                                                            <input  ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="accLineCtrl.accountLine.Reference1" name="Reference1" aria-label="Reference 1" required="true" />
                                                                        </p>
                                                                    </div>
                                                                </div>                                                        
                                                                <div class="row vertical-align">
                                                                    <div class="col-md-4" ng-if="accLineCtrl.TypeClassificationValueId===443">
                                                                        <req></req>
                                                                        <label>Receipt Date</label>
                                                                    </div>
                                                                    <div class="col-md-4" ng-if="accLineCtrl.TypeClassificationValueId===445">
                                                                        <req></req>
                                                                        <label>Posting Date</label>
                                                                    </div>                                                            
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm">
                                                                            <gts-date  ng-disabled="accLineCtrl.LockdownType>=4" format="yyyy/MM/dd" ng-model="accLineCtrl.accountLine.PostingDate" name="PostingDate">
                                                                            </gts-date>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="row vertical-align"  ng-if="accLineCtrl.TypeClassificationValueId===445">
                                                                    <div class="col-md-4">
                                                                        <req></req>
                                                                        <label>Reference 1</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm has-feedback"  ng-class="{'has-error': !accLineCtrl.accountLine.Reference1}">
                                                                            <input  ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="accLineCtrl.accountLine.Reference1" name="Reference1" aria-label="Reference 1" required="true" />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="row vertical-align">
                                                                    <div class="col-md-4" ng-if="accLineCtrl.TypeClassificationValueId===443">
                                                                        <opt></opt>
                                                                        <label>Reference</label>
                                                                    </div>                                                        
                                                                    <div class="col-md-4" ng-if="accLineCtrl.TypeClassificationValueId===445">
                                                                        <opt></opt>
                                                                        <label>Reference 2</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm">
                                                                            <input   ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="accLineCtrl.accountLine.Reference2" name="Reference2" aria-label="Reference 2" />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="row vertical-align" ng-if="accLineCtrl.TypeClassificationValueId===443">
                                                                    <div class="col-md-4">
                                                                    <opt></opt>
                                                                        <label>Bank</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm">
                                                                            <gts-dropdown  ng-disabled="accLineCtrl.LockdownType>=4" 
                                                                                        name="Bank" ng-model="accLineCtrl.accountLine.bank" 
                                                                                        load-data="accLineCtrl.loadBanks()"
                                                                                        ng-change="accLineCtrl.bankChanged(model)"
                                                                                        >
                                                                            </gts-dropdown>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="row vertical-align" ng-if="accLineCtrl.TypeClassificationValueId===443">
                                                                    <div class="col-md-4" >
                                                                        <opt></opt>
                                                                        <label>Account</label>
                                                                    </div>                                                        
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm">
                                                                            <input   ng-disabled="accLineCtrl.LockdownType>=4 || accLineCtrl.lockAccountNumber" 
                                                                                    class="form-control" type="text" 
                                                                                    ng-model="accLineCtrl.accountLine.bankAccountNumber" 
                                                                                    name="bankAccountNumber" 
                                                                                    aria-label="bankAccountNumber"                                                                                  
                                                                                />
                                                                        </p>
                                                                    </div>
                                                                </div>    
                                                                <div class="row vertical-align" ng-if="accLineCtrl.TypeClassificationValueId===443">
                                                                    <div class="col-md-4">
                                                                        <opt></opt>
                                                                        <label>Payment Method</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm">
                                                                            <gts-dropdown  ng-disabled="accLineCtrl.LockdownType>=4" 
                                                                                        name="Bank" ng-model="accLineCtrl.accountLine.PaymentMethod" 
                                                                                        load-data="accLineCtrl.loadPaymentMethods()"                                                                                        
                                                                                        >
                                                                            </gts-dropdown>
                                                                        </p>
                                                                    </div>
                                                                </div>                                                                                                            
                                                                <div class="row vertical-align">
                                                                    <div class="col-md-4">
                                                                        <req></req>
                                                                        <label>Amount</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <p class="input-group-sm">
                                                                            <input  ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" step="0.01" custom-format="number" ng-model="accLineCtrl.accountLine.Amount" name="Amount" aria-label="SuplierValue" />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </uib-tab>
                                                <uib-tab index="1" heading="Transactions" ng-if="accLineCtrl.TypeClassificationValueId===443">
                                                    <div class="gts-padding">
                                                        <div class="row">
                                                                <div class="col-md-8">
                                                                    <div class="row vertical-align">
                                                                        <div class="col-md-3">
                                                                            <opt></opt>
                                                                            <label>Reference 1</label>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <p class="input-group-sm has-feedback" >
                                                                                <input  ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="accLineCtrl.reference1Filter" name="Reference1Filter" aria-label="Reference 1"  />
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row vertical-align">
                                                                        <div class="col-md-3">
                                                                            <opt></opt>
                                                                            <label>Reference 2</label>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <p class="input-group-sm has-feedback">
                                                                                <input  ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="accLineCtrl.reference2Filter" name="Reference2Filter" aria-label="Reference 1"  />
                                                                            </p>
                                                                        </div>
                                                                    </div>  
                                                                    <div class="row vertical-align">
                                                                        <div class="col-md-3">
                                                                            <opt></opt>
                                                                            <label>Client Name</label>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <p class="input-group-sm has-feedback">
                                                                                <input  ng-disabled="accLineCtrl.LockdownType>=4" class="form-control" type="text" ng-model="accLineCtrl.clientNameFilter" name="ClientName" aria-label="Reference 1" />
                                                                            </p>
                                                                        </div>
                                                                    </div>        
                                                                    <div class="row vertical-align">
                                                                        <div class="col-md-3">
                                                                            <opt></opt>
                                                                            <label>Posting Date</label>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                            <form name="inputForm">
                                                                                <gts-date format="yyyy/MM/dd" ng-model="accLineCtrl.postingDateFromFilter" name="DateFrom"></gts-date>
                                                                            </form>
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                            <opt></opt>
                                                                            <label>To</label>
                                                                        </div>
                                                                        <div class="col-md-3">
                                                                           <form name="inputForm">
                                                                               <gts-date format="yyyy/MM/dd" ng-model="accLineCtrl.postingDateToFilter" name="DateTo"></gts-date>
                                                                            </form>
                                                                        </div>                                                                        
                                                                    </div> 
                                                                    <div class="row vertical-align">
                                                                        <div class="col-md-3">
                                                                            <opt></opt>
                                                                            <label>Tax Type</label>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <p class="input-group-sm">
                                                                                <gts-dropdown  ng-disabled="accLineCtrl.LockdownType>=4" 
                                                                                            name="Bank" ng-model="accLineCtrl.taxTypeFilter" 
                                                                                            load-data="accLineCtrl.loadTaxTypes()"
                                                                                            >
                                                                                </gts-dropdown>
                                                                            </p>
                                                                        </div>
                                                                    </div>       
                                                                    <div class="row vertical-align">
                                                                        <div class="col-md-2">
                                                                        </div>
                                                                        <div class="col-md-2">
                                                                        <button class="btn btn-primary"  
                                                                                ng-disabled="accLineCtrl.LockdownType>=4" 
                                                                                type="button" 
                                                                                ng-click="accLineCtrl.ApplyFilter()"                                                                               
                                                                                >
                                                                                Filter
                                                                        </button>                                                                               
                                                                        </div>
                                                                    </div>                                                                                                                                                                                                                                                                          
                                                                </div>
                                                               
                                                                    <div class="col-md-4">
                                                                        <div class="panel panel-default rounded" style="border-color: #bcd856;border-width: 3px;padding: 5px;">
                                                                            <div class="row vertical-align">
                                                                                <div class="col-md-6">
                                                                                    <b>Payment Amount</b>
                                                                                </div>
                                                                                <div class="col-md-6" style="text-align:right;">
                                                                                    {{accLineCtrl.accountLine.Amount  | number:2 }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row vertical-align">
                                                                                <div class="col-md-6">
                                                                                    <b>Total Selected</b>
                                                                                </div>
                                                                                <div class="col-md-6" style="text-align:right;">
                                                                                    {{accLineCtrl.totalAmount  | number:2 }}
                                                                                </div>
                                                                            </div>     
                                                                            <div class="row vertical-align">
                                                                                <div class="col-md-6" >
                                                                                
                                                                                </div>
                                                                                <div class="col-md-6" style="text-align:right;">
                                                                                    {{(accLineCtrl.accountLine.Amount - accLineCtrl.totalAmount)  | number:2 }}
                                                                                </div>
                                                                            </div>                                                                                                                                       
                                                                        </div>      
                                                                </div>                                                          
                                                        </div>

                                                        <div class="gts-padding">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="col-md-12">
                                                                        <div ui-grid="accLineCtrl.gvwDetails" ui-grid-pagination ui-grid-resize-columns ui-grid-selection 
                                                                            ui-grid-cellNav ui-grid-edit ui-grid-row-edit class="gridSmallScreen"
                                                                           >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </uib-tab>
                                                </uib-tabset>
                                                <div class="modal-footer">
                                                    <button class="btn btn-primary"  
                                                            ng-disabled="accLineCtrl.LockdownType>=4 || !accLineCtrl.accountLine.Entity || !accLineCtrl.accountLine.PostingDate || !accLineCtrl.accountLine.Reference1 || !accLineCtrl.accountLine.Amount" 
                                                            type="button" 
                                                            ng-click="accLineCtrl.saveTransaction()"
                                                             ng-if="accLineCtrl.TypeClassificationValueId===445"
                                                            >
                                                            Submit
                                                    </button>
                                                    <button class="btn btn-primary"  
                                                            ng-disabled="accLineCtrl.LockdownType>=4" 
                                                            type="button" 
                                                            ng-click="accLineCtrl.selectedTab = 1"
                                                            ng-if="accLineCtrl.TypeClassificationValueId===443 && accLineCtrl.selectedTab===0"
                                                            >
                                                            Next
                                                    </button>                                                              
                                                    <button class="btn btn-primary" 
                                                    ng-disabled="accLineCtrl.LockdownType>=4 || !accLineCtrl.accountLine.Entity || !accLineCtrl.accountLine.PostingDate || !accLineCtrl.accountLine.Reference1 || !accLineCtrl.accountLine.Amount
                                                    || accLineCtrl.accountLine.Amount <= 0 || accLineCtrl.totalAmount <= 0 || ((accLineCtrl.totalAmount - accLineCtrl.accountLine.Amount) !== 0)" 
                                                            type="button" 
                                                            ng-click="accLineCtrl.saveReceipt()"
                                                            ng-if="accLineCtrl.TypeClassificationValueId===443 && accLineCtrl.selectedTab===1"
                                                            >
                                                            Submit
                                                    </button>
                                                    <button class="btn btn-primary" type="button" ng-click="accLineCtrl.cancel()">Close</button>
                                                </div>
                                            
                                        </div>
                                    </form>
                                </div>
                        `,
                    controller: class accLineCtrl {
                        messages: interfaces.applicationcore.IMessage[];
                        accountLine: interfaces.customs.IAccountLine;                     
                        TypeClassificationValueId:number;
                        selectedTab: number;
                        lockAccountNumber: boolean = false;
                        totalAmount: number = 0;    

                        //Filters
                        reference1Filter: string = "";
                        reference2Filter: string = "";
                        clientNameFilter: string = "";
                        postingDateFromFilter:  moment.Moment;
                        postingDateToFilter:  moment.Moment;
                        taxTypeFilter: interfaces.applicationcore.IDropdownModel;
                        taxTypeFilterId: number;

                        constructor(
                            private $scope: IAccountUpdateScope,
                            private $timeout: ng.ITimeoutService,
                            private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            public accountDetailService: services.customs.accountDetailService,
                            private bsLoadingOverlayService,
                            private $q: ng.IQService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            public paymentMethodService: services.master.paymentMethodService,
                            public bankService: services.master.bankService,
                            private taxTypeService: services.tariff.taxTypeService,
                            private LockdownType : number,
                            private accountEntityId : number,
                            private declarationCountryId : number,
                            private accountCurrencyId : number
                        ) {                          

                            this.TypeClassificationValueId = TypeClassificationValueId;
                            console.log("TypeClassificationValueId",TypeClassificationValueId);
                            var loadPromises: ng.IPromise<any>[] = [];
                            loadPromises.push(this.loadAccountLine(accountLineId, accountPeriodId, accountId));
                            
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'acclines.update'
                            },
                                () => {
                                    return $q.all(loadPromises);
                                });                           
                        }

                        ApplyFilter(){
                            let filter: interfaces.customs.AvailableLinesFilter = {
                                reference1: this.reference1Filter,
                                reference2: this.reference2Filter,
                                clientName: this.clientNameFilter,
                                postingDateFrom: this.postingDateFromFilter,
                                postingDateTo: this.postingDateToFilter,
                                taxTypeFilterId: this.taxTypeFilter? this.taxTypeFilter.Id : null,  // Correct property name based on the interface
                                accountDetailId: this.accountLine.Id,  // Required field based on the interface
                                accountCurrencyId: this.accountCurrencyId  // Required field based on the interface
                            };

                         

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'account.update'
                            },
                                () => {
                                    return  this.accountDetailService.getAvailableLinesToLink().query(filter, (data: Array<interfaces.customs.IAccountLineDisplay>) => {                                    
                                        this.gvwDetails.data = data;

                                        // After setting the data, select the rows based on the 'Selected' property
                                        this.apiACCDetailList.grid.modifyRows(this.gvwDetails.data); // Ensure the rows are recognized by the grid

                                        this.gvwDetails.data.forEach((row:interfaces.customs.IAccountLineDisplay) => {
                                            if (row.Selected) {
                                                this.apiACCDetailList.selection.selectRow(row); // Select the row where 'Selected' is true
                                            }
                                        });

                                    }, (errorResponse) => {
                                       
                                      
                                    });
                                });
                            
                        }

                        apiACCDetailList: uiGrid.IGridApi;
                        registerGridApi(gridApi: uiGrid.IGridApi) {
                            this.apiACCDetailList = gridApi;
                            const ctrl = this; // Store the controller reference

                            // Hook row selection changed event to calculate the sum
                            gridApi.selection.on.rowSelectionChanged(this.$scope, (row) => {
                                ctrl.calculateSelectedAmount(); // Use the stored reference instead of "this"
                            });

                            // Hook batch selection changed event to calculate the sum when multiple rows are selected at once
                            gridApi.selection.on.rowSelectionChangedBatch(this.$scope, (rows) => {
                                ctrl.calculateSelectedAmount(); // Use the stored reference instead of "this"
                            });
                                
                        }

                        calculateSelectedAmount() {
                            let selectedRows = this.apiACCDetailList.selection.getSelectedRows();
                            let totalAmount = 0;
                        
                            selectedRows.forEach(row => {
                                if (row.Amount) {
                                    totalAmount += parseFloat(row.Amount);
                                }
                            });
                        
                            this.totalAmount = parseFloat(totalAmount.toFixed(2));
                            // You can display the totalAmount in the UI as required
                        }

                        gvwDetails: uiGrid.IGridOptions = {
                            data: [],
                            enableFiltering: true,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false, 
                            enableColumnResizing: true,                            
                            enableHorizontalScrollbar: 2,
                            rowEditWaitInterval: -1,
                            cellEditableCondition: true,
                            showGridFooter: true,
                            enablePaginationControls: true, // Enable pagination controls
                            paginationPageSizes: [10, 25, 50], // Set available page sizes
                            paginationPageSize: 10, // Set the default page size
                            multiSelect: true, // Allow multi-selection
                            enableRowSelection: true, // Enable row selection
                            enableSelectAll: true, // Enable "Select All" checkbox
                            enableFullRowSelection: false, // Unset full-row selection so the checkbox is separate
                            onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                            columnDefs: [{
                                name: "ID",
                                displayName: "ID",
                                field: "Id",
                                visible: false,
                                enableFiltering: false
                            },
                            {
                                name: "EntityCode",
                                displayName: "Entity",
                                field: "EntityCode",
                                width: 50,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                    }
                                }, 
                            },
                            {
                                name: "PostingDate",
                                displayName: "Posting Date",
                                field: "PostingDate",
                                width: 100,
                                cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        if (!searchTerm) return true; // Show all rows if no search term is provided
                                        if (!cellValue) return false; // If cell value is empty, return false
                            
                                        // Convert cellValue to "YYYY/MM/DD" format using moment.js
                                        const formattedCellValue = moment(cellValue).format("YYYY/MM/DD");
                            
                                        // Perform a case-insensitive contains match
                                        return formattedCellValue.toLowerCase().includes(searchTerm.toLowerCase());
                                    }
                                }
                            },
                            {
                                name: "Reference1",
                                displayName: "Reference 1",
                                field: "Reference1",
                                width: 130,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                    }
                                }
                            },
                            {
                                name: "Reference2",
                                displayName: "Reference 2",
                                field: "Reference2",
                                width: 130,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                    }
                                }
                            }, 
                            {
                                name: "Consignment",
                                displayName: "Consignment",
                                field: "Consignment",
                                width: 130,
                                cellTemplate: `<div ng-if="row.entity.consignmentId" class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">
                                                    <a style="cursor:pointer" ng-click="grid.appScope.accLineCtrl.ShowConsignment(row.entity.consignmentId, row.entity.IsInbound)"><u>{{MODEL_COL_FIELD}}</u></a>
                                                </div>
                                                <div ng-if="!row.entity.consignmentId" class="ui-grid-cell-contents"></div>`,       
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                    }
                                }
                            },                 
                            {
                                name: "ClientName",
                                displayName: "ClientName",
                                field: "ClientName",
                                width: 150,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                    }
                                }
                            },                                             
                            {
                                name: "Type",
                                displayName: "Type",
                                field: "Type",
                                width: 100,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                    }
                                }
                            }, 
                            {
                                name: "TaxType",
                                displayName: "Tax Type",
                                field: "TaxType",
                                width: 150,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        return (cellValue.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                    }
                                }
                            }, 
                            {
                                name: "Amount",
                                displayName: "Amount",
                                field: "Amount",
                                cellFilter: "number: 2",
                                width: 150,
                                filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                                filter: {
                                    condition: (searchTerm, cellValue) => {
                                        if (!searchTerm) return true; // Show all if search term is empty
                                        const term = parseFloat(searchTerm);
                                        const value = parseFloat(cellValue);
                                        return !isNaN(term) && !isNaN(value) && value.toFixed(2).includes(term.toFixed(2));
                                    }
                                }
                            }]
                        };
            

                        ShowConsignment(consignmentId:number,IsInbound:boolean){
                            var url = "";
                            if (IsInbound){
                                url = '#!/ConsignmentImports/UpdateImport/'+consignmentId;
                            }
                            else{                    
                                url = '#!/ConsignmentExports/UpdateExport/'+consignmentId;
                            }
                    
                            window.open(url, '_blank');
                        }
            

                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        loadType() {
                            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                                classificationId: Enum.ClassificationEnum.CustomsAccountDetailType
                            }, () => {
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        }

                        loadTaxTypes(){
                            return this.taxTypeService
                            .getDropdownList(this.declarationCountryId)
                            .query(
                              () => {},
                              failureData => {
                                this.generalService.displayMessageHandler(<
                                  interfaces.applicationcore.IMessageHandler
                                >failureData.data);
                              }
                            ).$promise;
                        }

                        loadPaymentMethods() {
                            return this.paymentMethodService.getDropdownList(this.accountLine.Entity.Id).query({
                            }, (resultList) => {
                            }).$promise;
                        }

                        loadBanks() {
                            return this.bankService.getDropdownList(this.accountLine.Entity.Id, "").query({
                            }, (resultList) => {
                            }).$promise; 
                        }

                        bankChanged(model: interfaces.applicationcore.IDropdownModel) {
                            if (model){
                                this.loadBank(model.Id);
                            }
                        }


                        loadBank(bankId:number) {
                            return this.bankService.getBank().query({ Id: bankId }, (data: interfaces.master.IBankMaster) => {
                                this.lockAccountNumber = false;
                                if (data.AccountNumber){
                                    this.accountLine.bankAccountNumber = data.AccountNumber;    
                                    this.lockAccountNumber = true;
                                }
                                if (data.IbanCode){
                                    this.accountLine.bankAccountNumber = data.IbanCode;    
                                    this.lockAccountNumber = true;
                                }                                
                                
                            });
                        }
            


                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                () => {
                                }, (failureData) => {
                                }).$promise;
                        }

                        SaveAccount(): ng.IPromise<boolean> {
                            var deferre = this.$q.defer<boolean>();

                            if (this.apiACCDetailList){
                           // Get the selected rows from the grid
                            const selectedRows: Array<interfaces.customs.IAccountLineDisplay> = this.apiACCDetailList.selection.getSelectedRows();        
                            
                             // Set the selected rows to the 'SelectedTransactions' property of your IAccountLine object
                            this.accountLine.SelectedTransactions = selectedRows;
                            }

                            this.accountDetailService.save().save(this.accountLine, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(data);
                                deferre.resolve(true);
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                deferre.resolve(false);
                            });

                            return deferre.promise;
                        };

                        saveReceipt(){
                            this.accountLine.TypeClassificationValueId = 443;
                            this.save();
                        }

                        saveTransaction(){
                            this.accountLine.TypeClassificationValueId = 445;
                            this.save();
                        }

                        save() {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'account.update'
                            },
                                () => {
                                    return this.SaveAccount().then((data: boolean) => {
                                        if (data) {
                                            this.$uibModalInstance.close(true);
                                        }
                                    });
                                });
                        }

                        loadAccountLine(accountLineId: number, accountPeriodId: number, accountId: number) {
                            return this.accountDetailService.getAccountLineDetail(accountLineId, accountPeriodId, accountId).query((data: interfaces.customs.IAccountLine) => {
                                this.accountLine = data;        
                                this.gvwDetails.data = this.accountLine.SelectedTransactions;     
                                
                                this.$timeout(() => {
                                    if (Array.isArray(this.gvwDetails.data) && this.apiACCDetailList) {
                                      
                                                // After setting the data, select the rows based on the 'Selected' property
                                                this.apiACCDetailList.grid.modifyRows(this.gvwDetails.data); // Ensure the rows are recognized by the grid

                                                this.gvwDetails.data.forEach((row:interfaces.customs.IAccountLineDisplay) => {                                                    
                                                    if (row.Selected) {
                                                        this.apiACCDetailList.selection.selectRow(row); // Select the row where 'Selected' is true
                                                    }
                                                });

                                                this.apiACCDetailList.core.refresh(); // Force the grid to refresh and update the UI
                                        }
                                });

                                if (this.accountLine.bank && this.accountLine.bank.Id > 0){ 
                                    this.loadBank(this.accountLine.bank.Id);
                                }
                            }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        }                        

                    },
                    controllerAs: "accLineCtrl",
                    resolve: {
                        LockdownType: this.account.LockdownType,
                        accountEntityId : this.account.EntityId,
                        declarationCountryId : this.account.DeclarationCountry.Id,
                        accountCurrencyId : this.account.Currency.Id
                    }
                }).result.then((result) => {
                    if (result) {
                        this.loadAccount();
                    }
                });
            }
            
            loadAccountLines() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'account.update'
                }, () => {
                        return this.accountDetailService.getAccountLines().query({
                            accountId: this.account.Id,
                            periodId: this.account.DetailPeriodNumber,
                        }, (result: Array<interfaces.customs.IAccountLineDisplay>) => {

                                this.account.AccountLineList = result;
                                this.gvwDetails.data = result;
                                this.account.DetailCurrentPeriod = result.reduce((sum: number, b: interfaces.customs.IAccountLineDisplay) => sum+ b.Amount, 0).toString();

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }

            loadDeclarationCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }
            declarationCountryChange() {
                this.account.CustomsOfficeCode = undefined;
            }
            loadCustomsOffices(searchText: string) {
                if (this.account.DeclarationCountry.Id > 0) {
                    return this.customsOfficeCodeService.getForDropdown(searchText, this.account.DeclarationCountry.Id).query().$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }
            
            loadAccountPeriods() {
                return this.accountPeriodService.getAccountPeriodsForDropdown(this.account.Id).query((successList) => {
                    this.accountPeriodList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadCurrencies() {
                return this.currencyService.getCurrencyDropdownList().query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadCustomParties() {
                return this.customsPartyService.getList().query((successList) => {
                    this.customsPartyList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            
            loadUsers(searchText: string) {
                if (this.account) {
                    return this.userAccountService.getDropdownList(searchText, this.account.EntityId).query(
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            SaveAccount(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();
                
                this.accountService.save().save(this.account, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    if (!data.HasErrorMessage){
                        this.accId = Number(data.ID);
                        deferre.resolve(true);
                    }else{
                        deferre.resolve(false);
                    }

                    
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'account.update'
                },
                    () => {
                        return this.SaveAccount().then((data: boolean) => {
                            if (data) {
                                this.loadAccount();
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.Account.Update", { accId: this.accId });
                            }
                        });
                    });

            }
            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'account.update'
                }, () => {
                        return this.SaveAccount().then(() => {
                        this.Close();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.$anchorScroll("topAnchor");
                    });
                });
            }
            Close() {
                this.$state.go("^");
            }
        };

        angular.module("app").controller("accountUpdateCtrl", controllers.customs.accountUpdateCtrl);
    }
}
